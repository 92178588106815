import * as React from "react";
import { Helmet } from "react-helmet";
import "../styles/global.css";
import "../styles/earthrise.css";
import "../styles/earthrise-portfolio.css";
import "../styles/earthrise-services.css";
import JSONData from "../content/services-items.json";
import Font1 from "../../static/fonts/made_evolve_sans_medium-webfont.woff2";
import Font2 from "../../static/fonts/made_evolve_sans_regular-webfont.woff2";

// markup
const ServicesPage = () => {
	return (
		<>
			<Helmet>
				<title>Earthrise Tech - Services</title>
				<meta name="description" content="Earthrise Tech has developed expertise in various sophisticated services in urban planning, demonstrating competencies in a wide range of skills." />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font1} />
				<link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href={Font2} />
			</Helmet>
			<main className="er-main">
				<div id="erp-frame-master">
					<a className="er-a er-title-mini" href="/">Earthrise</a>
					<div className="er-title">Services</div>
					<div id="erp-frame-scrolly">
						<div id="ers-frame-grid">
							{JSONData
								.map(e => (
									<div
										className="erp-item"
										key={e.title}
									>
										<div className="erp-item-title">{e.title}</div>
										{e.subtitle ? <div className="erp-item-subtitle">{e.subtitle}</div> : null}
										{e.description ? <div className="erp-item-description">{e.description}</div> : null}
										{e.bullets ?
											<ul>
												{e.bullets.map(b => <li class="erp-item-bullet" key={b}>{b}</li>)}
											</ul>
											: null}
									</div>
								))}
						</div>
					</div>
				</div>
			</main>
		</>
	)
}

export default ServicesPage
